<!--
File: TestRMPlanDetailEditForm.vue
Description: form for adding/editing a single RM Plan Detail entry.
-->
<template>
  <modal v-if="eligible" :title="screenTitle" :form="formName" :errCount="errors.count()"
    :hasUnsavedChanges="hasUnsavedChanges" @close="$emit('close')" @save="validate">

    <template slot='body'>
      <div class="md-layout">
        <div class="md-layout-item md-size-100">
          <RoadsDropdown v-model="roadId" @input="onRoadChange" disabled />
        </div>

        <div class="md-layout-item md-size-100">
          <BaseDropdown :class="getClass('fk_treatment')" :label="$t('fk_treatment')" v-model="fk_treatment"
            :items="treatmentsList" data-vv-name="fk_treatment" v-validate="modelValidations.fk_treatment"
            @input="onTreatmentChange" required />
        </div>

        <div class="md-layout-item md-size-40">
          <UnitsDropdown :class="getClass('fk_unit')" v-model="fk_unit" data-vv-name="fk_unit"
            v-validate="modelValidations.fk_unit" required @input="onUnitChange" />
        </div>
        <div class="md-layout-item md-size-30">
          <md-field :class="getClass('start')">
            <label :for="start">{{ $t('start') }}</label>
            <md-input id="start" v-model="start" :min="0" required data-vv-name="start"
              v-validate="modelValidations.start" />
          </md-field>
        </div>
        <div class="md-layout-item md-size-30">
          <md-field :class="getClass('end')">
            <label :for="end">{{ $t('end') }}</label>
            <md-input id="end" v-model="end" :min="0" required data-vv-name="end" v-validate="modelValidations.end" />
          </md-field>
        </div>

        <div class="md-layout-item md-size-40">
          <FloatInput :class="getClass('volume')" :label="$t('volume')" v-model="volume" data-vv-name="volume"
            v-validate="modelValidations.volume" :step="0.1" required />
        </div>
        <div class="md-layout-item md-size-40">
          <FloatInput :class="getClass('cost')" :label="$t('tables.cost')" v-model="cost" data-vv-name="cost"
            v-validate="modelValidations.cost" :step="0.1" required />
        </div>
        <div class="md-layout-item md-size-20">
          <md-field :class="getClass('cycle_ratio')">
            <label :for="cycle_ratio">{{ $t('cycle_ratio') }}</label>
            <md-input id="cycle_ratio" v-model="cycle_ratio" required data-vv-name="cycle_ratio"
              v-validate="modelValidations.cycle_ratio" />
          </md-field>
        </div>

        <span> {{ $t('total_cost') }}: {{ total_cost }} </span>
      </div>
    </template>
  </modal>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import { Modal, BaseDropdown, RoadsDropdown, UnitsDropdown, FloatInput } from '@/pages/Components'
import { savedMessage } from '@/mixins/messagesMixin'
import permissions from "@/mixins/permissionsMixin"

export default {
  name: 'rm-plan-edit-form',
  mixins: [permissions],

  data() {
    return {
      // TODO: change to appropriate name and add to the permissions table
      formName: 'DeuEditForm',
      eligible: false,

      fk_treatment: 0,
      //fk_road: 0,
      start: 0,
      end: 0,
      fk_unit: 0,
      cost: 0,
      volume: 0,
      cycle_ratio: 1,

      // TODO: remove 3 lines below after API implmenetation
      treatment: null,
      units: null,
      road: null,

      initialFormState: null,
      modelValidations: {
        fk_treatment: { required: true, numeric: true, min_value: 1 },
        //fk_road: { required: true, numeric: true, min_value: 1 },
        start: { required: true, numeric: true },
        end: { required: true, numeric: true },
        fk_unit: { required: true, numeric: true, min_value: 1 },
        cost: { required: true, decimal: true, min_value: 0.1 },
        volume: { required: true, decimal: true, min_value: 0.1 },
        cycle_ratio: { required: true, numeric: true },
      }
    }
  },

  props: {
    planId: null,
    roadId: null,
    regionId: null,
    itemId: null
  },

  components: {
    Modal,
    BaseDropdown,
    RoadsDropdown,
    UnitsDropdown,
    FloatInput,
  },

  async mounted() {
    // Check if we are eligible to view the form
    this.eligible = await this.checkIfScreenAllowed();
    if (!this.eligible) {
      this.$emit('close');
      return;
    };

    if (this.itemId) {
      const theItem = await this.loadItem(this.itemId);
      this.$nextTick(() => {
        this.fk_treatment = theItem.fk_treatment;
        //this.fk_road = theItem.fk_road;
        this.start = theItem.start;
        this.end = theItem.end;
        this.fk_unit = theItem.fk_unit;
        this.cost = theItem.cost;
        this.volume = theItem.volume;
        this.cycle_ratio = theItem.cycle_ratio;
      });
    };
    this.$nextTick(async () => {
      await this.loadTreatments(false);
      this.initialFormState = this.getCurrentState;
      await this.$validator.validateAll();
    });
  },

  methods: {
    ...mapActions({
      loadTreatments: 'LOAD_RM_TREATMENTS',
      loadItem: 'TestRM/LOAD_RM_PLAN_DETAIL_BY_ID',
      addItem: 'TestRM/ADD_NEW_RM_PLAN_DETAIL',
      editItem: 'TestRM/UPDATE_RM_PLAN_DETAIL',
      highlightRow: 'HIGHLIGHT_ROW'
    }),

    onTreatmentChange(id, desc) {
      // TODO: remove this method after API implmentation, as well as in the template section
      console.log('onTreatmentChange', id, desc)
      this.treatment = desc
    },

    onRoadChange(id, desc) {
      // TODO: remove this method after API implmentation, as well as in the template section
      this.road = desc
    },

    onUnitChange(id, desc) {
      // TODO: remove this method after API implmentation, as well as in the template section
      this.units = desc
    },

    async validate() {
      const isValid = await this.$validator.validateAll()
      if (!isValid) return

      const theItem = this.getCurrentState;
      // TODO: remove 3 lines below after API implmenetation
      theItem.treatment_description = this.treatment;
      theItem.road_description = this.road;
      theItem.units = this.units;
      //theItem.road_description = this.road

      let errDesc = '';
      let newId;
      const action = !this.itemId ? this.addItem : this.editItem;
      const payload = !this.itemId ? theItem : { id: this.itemId, theItem };
      try {
        const res = await action(payload)
        newId = res?.id;
      } catch (err) {
        errDesc = err.message || this.$t('messages.unknown_error')
      }

      this.$nextTick(() => this.$validator.reset())
      this.$emit('close');
      await savedMessage(errDesc, this.$t('stdCols.plan_name'), this.plan_name)
      this.highlightRow(newId)
    },
  },

  computed: {
    ...mapState({
      treatmentsList: (state) => state.Dropdowns.rm_treatments,
    }),

    screenTitle() {
      return this.itemId ? this.$t('screen_titles.treatment_type_upd') : this.$t('screen_titles.treatment_type_add')
    },

    getCurrentState() {
      return {
        plan_id: this.planId,
        fk_road: this.roadId,
        fk_treatment: this.fk_treatment,
        start: this.start,
        end: this.end,
        fk_unit: this.fk_unit,
        cost: this.cost,
        volume: this.volume,
        cycle_ratio: this.cycle_ratio,
        total_cost: this.total_cost,
      }
    },

    hasUnsavedChanges() {
      if (!this.initialFormState) return false

      return this.fk_treatment !== this.initialFormState.fk_treatment ||
        //this.fk_road !== this.initialFormState.fk_road ||
        this.start !== this.initialFormState.start ||
        this.end !== this.initialFormState.end ||
        this.fk_unit !== this.initialFormState.fk_unit ||
        this.cost !== this.initialFormState.cost ||
        this.volume !== this.initialFormState.volume ||
        this.cycle_ratio !== this.initialFormState.cycle_ratio
    },

    total_cost() {
      const res = this.cost * this.volume * this.cycle_ratio;
      return res.toFixed(2);
    }
  }
}
</script>
<style lang="scss" scoped>
.modal-container {
  max-width: 900px;
  width: 900px;
}
</style>