import { render, staticRenderFns } from "./TestRMTreatmentSelection.vue?vue&type=template&id=28c00b85&scoped=true"
import script from "./TestRMTreatmentSelection.vue?vue&type=script&lang=js"
export * from "./TestRMTreatmentSelection.vue?vue&type=script&lang=js"
import style0 from "./TestRMTreatmentSelection.vue?vue&type=style&index=0&id=28c00b85&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28c00b85",
  null
  
)

export default component.exports