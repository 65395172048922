<!--
File: TestRMPlanDetails.vue
Description: show list of DEU's entered in the DB.
-->
<template>
  <base-data-table v-if="eligible" :loading="showSpinner" :pagination="pagination" :btnAddAllowed="false" :from="from"
    :to="to" :total="total" @add-item="viewItem()">

    <template #table-rows>
      <md-table class="paginated-table table-striped table-hover" :value="queriedData" :md-sort.sync="currentSort"
        :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort" md-fixed-header>

        <md-table-empty-state :md-label="$t('label.no_data')" :md-description="$t('messages.select_another_criteria')" />
        <md-table-row slot="md-table-row" slot-scope="{item}" :class="{ blinking: item[idFieldName] === highlightedRow }"
          :id="item[idFieldName] === highlightedRow ? 'highlightedRow' : null">

          <md-table-cell v-for="header in headers" :key="header.value" :md-label="$t(header.text)"
            :md-sort-by="header.sortable ? header.value : null" :md-numeric="header.format === 'number'">
            {{ item[header.value] }}
          </md-table-cell>

          <md-table-cell :md-label="$t('tables.actions')">
            <md-button v-if="isBtnAllowed('EditButton')" class="md-just-icon md-default md-simple" style="margin: 3px 0;"
              :title="$t('buttons.copy')" @click="copyItem(item)">
              <md-icon>file_copy</md-icon>
            </md-button>
            <table-actions :btnEditAllowed="isBtnAllowed('EditButton')" :btnDeleteAllowed="isBtnAllowed('DeleteButton')"
              @edit="viewItem(item[idFieldName])" @delete="deleteItem(item[idFieldName], item.description)" />
          </md-table-cell>
        </md-table-row>
      </md-table>
    </template>

    <template #editing-dialog>
      <EditForm v-if="showDetailsDlg" :planId="selectedPlan" :roadId="selectedRoad" :itemId="currentId"
        :regionId="selectedRegion" @close="onEditFormClose" />
    </template>
  </base-data-table>
</template>
<script>
  import { mapState, mapActions, mapGetters } from 'vuex'
  import BaseDataTable from "@/pages/Components/BaseDataTable.vue"
  import BaseTableMixin from '@/mixins/BaseTableMixin'
  import TableActions from "@/pages/Components/TableActions.vue"
  import { savedMessage } from '@/mixins/messagesMixin'
  import EditForm from './TestRMPlanDetailEditForm.vue'

  export default {
    name: 'test-rm-plan-details',
    mixins: [BaseTableMixin],

    components: {
      BaseDataTable,
      TableActions,
      EditForm
    },

    data() {
      return {
        // TODO: change to appropriate name and add to the permissions table
        formName: 'Deu',
        idFieldName: 'id',
        propsToSearch: ['treatment_description', 'units'],
        currentSort: 'year',

        payload: { plan_id: -1 },
        selectedRM: -1,
        selectedRoad: -1,

        historyMapping: {
          selectedRegion: 0,
          currentSort: 'description',
          currentSortOrder: 'asc',
          pagination: this.pagination,
          searchQuery: ''
        }
      }
    },

    props: {
      step: { default: '', type: String },
      filterParams: { default: {}, type: Object },
    },

    async mounted() {
      this.payload.rm_id = -1;
    },

    methods: {
      ...mapActions({
        clearList: 'TestRM/CLEAR_RM_TREATMENT_LIST',
        loadPlanYears: 'TestRM/LOAD_RM_PLAN_LIST',
        getPlanDropdown: 'LOAD_RM_PLANS',
        loadPlans: 'TestRM/LOAD_RM_PLAN_LIST',
        loadList: 'TestRM/LOAD_RM_PLAN_DETAIL_LIST',
        addItem: 'TestRM/ADD_NEW_RM_PLAN_DETAIL',
        delete: 'TestRM/DELETE_RM_PLAN_DETAIL',
        approvePlan: 'TestRM/APPROVE_PLAN',
        cancelPlan: 'TestRM/CANCEL_PLAN',
      }),

      toggleSpinner(state) {
        this.showSpinner = state
      },

      async reloadList() {
        this.toggleSpinner(true);

        this.selectedRegion = this.filterParams?.fk_region;
        const planFilter = {
          fk_year: this.filterParams?.fk_year,
          fk_region: this.filterParams?.fk_region,
          fk_deu: this.filterParams?.fk_deu ? this.filterParams.fk_deu : -1,
          rm_id: this.filterParams?.rm_id || -1 // in order not to select other plans
        };
        console.log("planFilter:", planFilter);
        await this.loadPlans(planFilter);

        this.$emit('labelChanged', this.planApproved ? 'Plan approved' : '');
        console.log('currentPlan', this.currentPlan, this.plans);

        // Do not show anything until user selects a road
        this.selectedRoad = this.filterParams?.fk_road || -1;
        this.payload = { plan_id: this.currentPlan, fk_road: this.selectedRoad };
        console.log("payload:", this.payload);

        await this.loadList(this.payload);
        this.toggleSpinner(false);
      },

      async copyItem(item) {
        let errDesc = '';
        let newId;
        delete item.id
        try {
          const res = await this.addItem(item);
          newId = res?.id;
          console.log('newId', newId)
        } catch (err) {
          errDesc = err.message || this.$t('messages.unknown_error');
        }
        await savedMessage(errDesc, this.$t('stdCols.plan_name'), this.plan_name);
        await this.reloadList();

        this.highlightRow(newId)
      },

      handleClick(header, item) {
        if (header.onClick && typeof this[header.onClick] === "function") {
          this[header.onClick](item);
        }
      },

      async onEditFormClose() {
        this.showDetailsDlg = false;
        await this.loadPlans(this.payload);
        //console.log(JSON.stringify(this.tableData))
      },
    },

    computed: {
      ...mapState({
        tableData: (state) => state.TestRM.rm_plan_detail_list,
        plans: (state) => state.TestRM.rm_plan_list,
      }),

      // TODO: make a getter that returns only roads in the plans
      ...mapGetters('TestRM', ['yearsInPlanList']),

      headers() {
        return [
          { text: "plan_id", value: "plan_id", sortable: true },
          { text: "road_network.road", value: "road_description", sortable: true },
          { text: "start", value: "start", sortable: true, format: 'number' },
          { text: "end", value: "end", sortable: true, format: 'number' },
          { text: "treatment_description", value: "treatment_description", sortable: true },
          { text: "units", value: "units", sortable: true },
          { text: "volume", value: "volume", sortable: true, format: 'number' },
          { text: "cost", value: "cost", sortable: true, format: 'number' },
          { text: "cycle_ratio", value: "cycle_ratio", sortable: true, format: 'number' },
          { text: "total_cost", value: "total_cost", sortable: true, format: 'number' },
        ]
      },

      planExists() {
        return this.plans.length > 0
      },

      planApproved() {
        return this.planExists ? this.plans[0].approvedBy !== null : false;
      },

      selectedPlan() {
        const res = this.filterParams?.fk_plan || -1;
        console.log('selectedPlan', res)
        return res;
      },

      currentPlan() {
        return this.planExists ? this.plans[0].id : -1;
      },

      btnAddDisabled() {
        return !this.selectedPlan;
      },

    },

    watch: {
      step(newValue, oldValue) {
        console.log("PlanDetails step", newValue, oldValue);
        if (newValue !== oldValue && newValue === 'second') this.reloadList();
      },

      filterParams: {
        async handler(value) {
          if (this.step !== 'second') return;

          console.log("PlanDetails paramChanged:", value?.year, value?.fk_region, value?.fk_deu, value?.rm_id);
          await this.reloadList()
        },
        deep: true
      },

      /*
      selectedPlan() {
        console.log("selectedPlan changed, emitting...", this.btnAddDisabled);
        this.$emit('changeAddButtonStatus', 'detailsForm', this.btnAddDisabled);
      },
      */
    }
  }
</script>
<style lang="scss" scoped>
@import '@/mixins/BaseTable.scss';
</style>