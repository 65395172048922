<!--
File: TestRMSelectionParams.vue
Description: show list of Routine Maintenance Plans.
-->
<template>
  <md-card style="width: 100%; display: block;">
    <md-card-header class="md-card-header-icon md-card-header-green">
      <div class="md-layout">
        <div class="md-layout-item md-small-size-20 md-size-10">
          <YearsDropdown v-model="selectedYear" :items="yearsInPlanList" />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-15">
          <RegionsDropdown v-model="selectedRegion" />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-10">
          <DepsDropdown v-model="selectedDeu" :regionId="selectedRegion" :disabled="!selectedRegion" />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-15">
          <BaseDropdown v-model="selectedRM" :label="$t('Maintenance')" :items="routineMaintenanceList"
            :disabled="!selectedDeu" />
        </div>
        <!--div class="md-layout-item md-small-size-100 md-size-15">
          <BaseDropdown v-if="step === 'second'" :label="$t('label.select_plan')" v-model="selectedPlan" :items="planList"
            :disabled="!selectedRegion" @input="onPlanChange" />
        </div-->
        <div class="md-layout-item md-small-size-100 md-size-15">
          <RoadsDropdown v-if="step === 'second'" v-model="selectedRoad" :regionId="selectedRegion"
            :disabled="!selectedDeu" />
        </div>
        <!--div class="md-layout-item md-small-size-100 md-size-15">
          <search-input v-model="searchQuery" />
        </div-->

        <span style="color: red;">{{ label }}</span>

        <div class="md-layout-item btn-row md-small-size-100">
          <md-button v-if="btnAddShown" class="md-success" @click="$emit('add-item')">
            {{ $t('buttons.add') }}
          </md-button>
        </div>
      </div>
    </md-card-header>
  </md-card>
</template>
<script>
  import { mapState, mapActions, mapGetters } from 'vuex';
  import { BaseDropdown, YearsDropdown, RegionsDropdown, DepsDropdown, RoadsDropdown } from '@/pages/Components'
  import SearchInput from "@/pages/Components/SearchInput.vue";
  export default {
    name: 'selection-params',

    data() {
      return {
        // TODO: change to appropriate name and add to the permissions table
        formName: 'Budgets',

        selectedYear: null,
        selectedRegion: null,
        selectedDeu: null,
        selectedRM: null,
        selectedRoad: null,
        selectedPlan: null,
        searchQuery: null,

        planName: null,
      }
    },

    props: {
      step: { default: 'first', type: String },
      btnAddShown: { default: true, type: Boolean },
      label: { default: '', type: String },
    },

    components: {
      BaseDropdown,
      YearsDropdown,
      RegionsDropdown,
      DepsDropdown,
      RoadsDropdown,
      SearchInput,
    },

    async mounted() {
      await this.loadPlanYears({})
    },

    methods: {
      ...mapActions({
        loadPlanYears: 'TestRM/LOAD_RM_PLAN_LIST',
        getPlanDropdown: 'LOAD_RM_PLANS',
        /*loadList: 'TestRM/LOAD_RM_PLAN_LIST',
        delete: 'TestRM/DELETE_RM_PLAN',
        approvePlan: 'TestRM/APPROVE_PLAN',
        cancelPlan: 'TestRM/CANCEL_PLAN',*/
      }),

      onPlanChange(value, desc) {
        this.planName = desc;           // We will need it to show in the Excel
        //this.payload.plan_id = value;
      },

      async reloadPlans() {
        const params = {
          year: this.selectedYear,
          fk_region: this.selectedRegion,
          fk_deu: this.selectedDeu,
          rm_id: this.selectedRM
        };
        await this.getPlanDropdown(params)
      },
    },

    computed: {
      ...mapState({
        //tableData: (state) => state.TestRM.rm_plan_list,
        planList: (state) => state.Dropdowns.rm_plans,
      }),

      ...mapGetters('TestRM', ['yearsInPlanList']),

      routineMaintenanceList() {
        return [
          { id: 0, description: '' },
          { id: 1, description: "Current Maintenance" },
          { id: 2, description: "Summer Maintenance" },
          { id: 3, description: "Winter Maintenance" },
        ]
      }
    },

    watch: {
      selectedYear(value) {
        this.reloadPlans();
        this.selectedRegion = null;
        this.$emit('paramChanged', 'year', value);
      },

      selectedRegion(value) {
        this.reloadPlans();
        //this.selectedPlan = null;
        this.$emit('paramChanged', 'fk_region', value);
      },

      selectedDeu(value) {
        this.reloadPlans();
        this.selectedRM = null;
        this.$emit('paramChanged', 'fk_deu', value);
      },

      selectedRM(value) {
        this.reloadPlans();
        this.selectedRoad = null;
        this.$emit('paramChanged', 'rm_id', value);
      },
/*
      selectedPlan(value) {
        this.selectedRoad = null;
        this.$emit('paramChanged', 'fk_plan', value);
      },
*/
      selectedRoad(value) {
        this.$emit('paramChanged', 'fk_road', value);
      },
    }
  }
</script>
<style lang="scss" scoped>
.md-card {
  margin: 0px 0;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
</style>