<!--
File: TestRM.vue
Description: show list of DEU's entered in the DB.
-->
<template>
  <div class="container">
    <VueTabs @tab-change="handleTabChange" class="tabs">
      <v-tab id="tab0" v-if="isTabAllowed(0)" :title="$t('Treatments assignment')" />
      <v-tab id="tab1" v-if="isTabAllowed(1)" :title="$t('Plan Details')" />
      <v-tab id="tab2" v-if="isTabAllowed(2)" :title="$t('tabs.approve_plan')" />
    </VueTabs>

    <selection-params 
      :step="activeTab" 
      :btnAddShown="btnAddShown" 
      :label="label" 
      @paramChanged="onParamChanged"
      @add-item="addItem" 
    />

    <TestRMTreatmentSelection 
      ref="selectionForm" 
      v-show="activeTab === 'first'" 
      :filterParams="filterParams" 
      :step="activeTab"
      @labelChanged="onLabelChanged" 
      @changeAddButtonStatus="onChangeAddButtonStatus" 
    />

    <DetailsForm 
      ref="detailsForm" 
      v-show="activeTab === 'second'" 
      :filterParams="filterParams" 
      :step="activeTab"
      @labelChanged="onLabelChanged" 
      @changeAddButtonStatus="onChangeAddButtonStatus" 
    />

    <RMPlans 
      ref="plansForm" 
      v-show="activeTab === 'third'" 
      :filterParams="filterParams" 
      :step="activeTab" 
    />
  </div>
</template>
<script>
import { VueTabs, VTab } from 'vue-nav-tabs';
import 'vue-nav-tabs/themes/vue-tabs.css';
import permissions from "@/mixins/permissionsMixin";
import SelectionParams from "./TestRMSelectionParams.vue";
import TestRMTreatmentSelection from "./TestRMTreatmentSelection.vue";
import RMPlans from './TestRMPlans';
import DetailsForm from './TestRMPlanDetails.vue';

export default {
  mixins: [permissions],
  name: 'PreparePlanForm',

  data() {
    return {
      activeTab: 'first',
      label: '',
      filterParams: {},
      currentForm: 'selectionForm',
      btnAddShown: false,
      selectedDeu: null,
      selectedRoad: null,
      selectedPlan: null,
    };
  },

  components: {
    VueTabs,
    VTab,
    RMPlans,
    DetailsForm,
    SelectionParams,
    TestRMTreatmentSelection,
  },

  mounted() {
    if (!this.checkIfScreenAllowed()) {
      this.onClose();
    }
  },

  methods: {
    handleTabChange(tabIndex) {
      this.label = '';
      this.activeTab = this.stepNames[tabIndex];
      this.currentForm = this.formNames[tabIndex];
    },

    onParamChanged(paramName, value) {
      this.$set(this.filterParams, paramName, value);
    },

    onLabelChanged(value) {
      this.label = value;
    },

    onChangeAddButtonStatus(form, value) {
      this.btnAddShown = value;
    },

    async addItem() {
      await this.$refs[this.currentForm].viewItem();
    },

    isTabAllowed(index) {
      // Implement your permission logic here
      return true;
    },
  },

  computed: {
    stepNames() {
      return ['first', 'second', 'third'];
    },
  
    formNames() {
      return ['selectionForm', 'detailsForm', 'plansForm'];
    },
},
};
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  display: block;
}
.tabs {
  width: 100%;
}
</style>