<!--
File: TestRMPlans.vue
Description: show list of Routine Maintenance Plans.
-->
<template>
  <base-data-table v-if="eligible" :loading="showSpinner" :pagination="pagination" :btnAddAllowed="false" :from="from"
    :to="to" :total="total" @add-item="viewItem()">

    <template #table-rows>
      <md-table class="paginated-table table-striped table-hover" :value="queriedData" :md-sort.sync="currentSort"
        :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort" md-fixed-header>
        <md-table-empty-state :md-label="$t('label.no_data')" :md-description="$t('messages.select_another_criteria')" />
        <md-table-row slot="md-table-row" slot-scope="{item}" :class="{ blinking: item[idFieldName] === highlightedRow }"
          :id="item[idFieldName] === highlightedRow ? 'highlightedRow' : null">

          <md-table-cell v-for="header in headers" :key="header.value" :md-label="$t(header.text)"
            :md-sort-by="header.sortable ? header.value : null" :md-numeric="header.format === 'number'">
            {{ item[header.value] }}
          </md-table-cell>

          <md-table-cell :md-label="$t('tables.actions')">
            <!-- condition: this.step === 'second' && item.treatment_count > 0 && this.isBtnAllowed('ShowDetailsButton'), -->
            <!--md-button v-if="isBtnAllowed('ApproveRevokeButton') && step === 'third'"
              class='md-just-icon md-success md-simple' @click="showAssignedTreatments(item.id)">
              <md-icon>preview</md-icon>
            </md-button-->

            <md-button v-if="isBtnAllowed('ApproveRevokeButton') && step === 'third'"
              :class="['md-raised', 'md-sm', item.approved_by ? 'md-primary' : 'md-success']" style="width: 100px;"
              @click="togglePlanApproval(item.id, item.plan_name, !item.approved_by)">
              {{ item.approved_by ? $t('buttons.revoke') : $t('buttons.approve') }}
            </md-button>

            <table-actions :btnEditAllowed="isBtnAllowed('EditButton') && !item.approved_by && step === 'first'"
              :btnDeleteAllowed="isBtnAllowed('DeleteButton') && !item.approved_by && step === 'third'"
              @edit="viewItem(item[idFieldName])" @delete="deleteItem(item[idFieldName], item.description)" />
          </md-table-cell>
        </md-table-row>
      </md-table>
    </template>

    <template #editing-dialog>
      <EditForm v-if="showDetailsDlg"  @close="onEditFormClose" ref="dynamicForm" v-model="currentPlan" :fields="fields"
    :validations="validations" />
    </template>
  </base-data-table>
</template>
<script>
  import { mapState, mapActions, mapGetters } from 'vuex';
  import BaseDataTable from "@/pages/Components/BaseDataTable.vue";
  import BaseTableMixin from '@/mixins/BaseTableMixin';
  import TableActions from "@/pages/Components/TableActions.vue";
  //import messagesMixin from '@/mixins/messagesMixin';
  import EditForm from '@/pages/Components/EditForm.vue'
  //import EditForm from './TestRMPlanEditForm.vue';

  export default {
    name: 'test-rm-plans-list',
    mixins: [BaseTableMixin],

    components: {
      BaseDataTable,
      TableActions,
      EditForm,
    },

    data() {
      return {
        // TODO: change to appropriate name and add to the permissions table
        formName: 'Budgets',
        eligible: true,
        idFieldName: 'id',
        propsToSearch: ['year', 'region_description', 'deu_description', 'road_description', 'plan_name'],
        currentSort: 'year',

        selectedPlan: null,

        historyMapping: {
          selectedRegion: 0,
          currentSort: 'description',
          currentSortOrder: 'asc',
          pagination: this.pagination,
          searchQuery: ''
        }, 

        currentPlan: {},
        fields: [
      { name: 'year', label: 'budget.select_year', type: 'text', class: 'md-layout-item md-size-40', props: { type: 'number' } },
      { name: 'fk_region', type: 'custom', component: 'RegionsDropdown', class: 'md-layout-item md-size-20' },
      { name: 'fk_deu', type: 'custom', component: 'DepsDropdown' , class: 'md-layout-item md-size-20', props: { regionId: this.currentPlan?.fk_region }},
      { name: 'fk_road', type: 'custom', component: 'RoadsDropdown', class: 'md-layout-item md-size-40', props: { regionId: this.currentPlan?.fk_region }},
    ],
        validations: {
          year: { required: true, numeric: true, min_value: 2024 },
          fk_region: { required: true, numeric: true, min_value: 1 },
          fk_deu: { required: true, numeric: true, min_value: 1 },
          fk_road: { required: true, numeric: true, min_value: 1 },
        }
      }
    },

    props: {
      step: { default: 'first', type: String },
      filterParams: { default: {}, type: Object },
    },

    async mounted() {
    },

    methods: {
      ...mapActions({
        clearList: 'TestRM/CLEAR_RM_TREATMENT_LIST',
        loadList: 'TestRM/LOAD_RM_PLAN_LIST',
        delete: 'TestRM/DELETE_RM_PLAN',
        approvePlan: 'TestRM/APPROVE_PLAN',
        cancelPlan: 'TestRM/CANCEL_PLAN',
      }),

      toggleSpinner(state) {
        this.showSpinner = state
      },

      handleClick(header, item) {
        if (header.onClick && typeof this[header.onClick] === "function") {
          this[header.onClick](item);
        }
      },

      showAssignedTreatments(id) {
        this.selectedPlan = id;
        //this.$emit('showDetails', id)
      },

      onEditFormClose() {
        this.showDetailsDlg = false;
        this.loadList(this.payload);
        //console.log(JSON.stringify(this.tableData))
      },

      async togglePlanApproval(id, name, status) {
        if (this.step !== 'third') return;

        this.toggleSpinner(true);
        const action = status ? this.approvePlan : this.cancelPlan;

        try {
          await action(id);
        } finally {
          this.toggleSpinner(false);
        }
        await this.reloadData();
        await this.approvedMessage(status, name);

        this.highlightRow(id);
      },

    },

    computed: {
      ...mapState({
        tableData: (state) => state.TestRM.rm_plan_list,
      }),

      ...mapGetters('TestRM', ['yearsInPlanList']),

      headers() {
        return [
          { text: "budget.year", value: "year", sortable: true },
          { text: "road_network.dep", value: "deu_description", sortable: true },
          { text: "stdCols.type", value: "rm_description", sortable: true },
          { text: "stdCols.amount", value: "amount", sortable: true },
          { text: "label.approved", value: "approved_by", sortable: true, condition: "item.approved_on !== null" },
          /*{
            text: "road_network.treatments_count", value: "treatments_count", sortable: true,
            onClick: this.handleSectionsClick, format: "number"
          },*/
        ]
      },
    },

    watch: {
      step(newValue, oldValue) {
        console.log("Plans step", newValue, oldValue);
        if (newValue !== oldValue)
          console.log("filterParams", this.filterParams);
        this.loadList(this.filterParams);
      },

      filterParams: {
        handler(value) {
          console.log("filterParams изменился:", value);
          this.loadList(value);
        },
        deep: true
      },
    }
  }
</script>
<style lang="scss" scoped>
@import '@/mixins/BaseTable.scss';
</style>