<!--
File: TestRMTreatmentSelection.vue
Description: shows list of treatments for selection in the Routine Maintenance Plans.
-->
<template>
  <md-card>
    <md-card-content>
      <div class="md-layout">
        <BaseTable class="md-layout-item md-size-50" v-model="selectedRoads" :items="roadsList"
          :headers="roadsHeaders" :loading="showSpinner" :isSelectable="!planApproved && selectedRM != -1 " />

        <BaseTable class="md-layout-item md-size-50" v-model="selectedTreatments" :items="treatmentsList"
          :headers="treatmentsHeaders" :loading="showSpinner" :isSelectable="!planApproved" />
      </div>
    </md-card-content>

    <md-card-actions class="md-layout">
      <div v-if="addButtonEnabled" class="md-layout md-small-size-100 md-size-50">
        <span>
          {{ $t('budget.selected') }} {{ selectedTreatments.length }} {{ $t('label.entries') }},
          {{ selectedRoads.length }} {{ $t('roads') }}
        </span>

        <!--div v-if="isBtnAllowed('AssignButton')"  class="md-layout-item md-small-size-100 md-size-40">
        <div  class="md-layout-item md-small-size-100 md-size-40">
          <md-button class="md-raised md-success" @click="viewItem()">
            {{ $t('label.assign_plan') }}
          </md-button>
        </div-->
      </div>
    </md-card-actions>
  </md-card>
</template>
<script>
  import { mapState, mapActions, mapGetters } from 'vuex'
  import permissions from "@/mixins/permissionsMixin"
  import BaseTable from "@/pages/Components/BaseTable.vue"
  import { savedMessage } from '@/mixins/messagesMixin'
  //import EditForm from './TestRMTreatmentEditForm.vue'

  export default {
    name: 'test-rm-treatment-selection',
    mixins: [permissions],

    components: {
      BaseTable,
      //EditForm
    },

    data() {
      return {
        // TODO: change to an appropriate name and add to the permissions table
        formName: 'Deu',
        showSpinner: false,

        currentSort: 'year',

        selectedDeu: -1,
        selectedRM: -1,
        selectedYear: null,

        selectedRoads: [],
        selectedTreatments: [],
      }
    },

    props: {
      step: { default: '', type: String },
      filterParams: { default: {}, type: Object },
    },

    async mounted() {
      await this.reloadList();
    },

    methods: {
      ...mapActions({
        loadTreatments: 'TestRM/LOAD_RM_TREATMENT_LIST',
        loadSections: 'LOAD_SECTION_LIST',
        clearSectionsList: 'CLEAR_SECTION_LIST',
        loadPlans: 'TestRM/LOAD_RM_PLAN_LIST',
        addNewPlan: 'TestRM/ADD_NEW_RM_PLAN',
        loadPlanDetails: 'TestRM/LOAD_RM_PLAN_DETAIL_LIST',
        addPlanDetails: 'TestRM/ADD_NEW_RM_PLAN_DETAIL',
        clearPlanDetailsList: 'TestRM/CLEAR_RM_PLAN_DETAIL_LIST',
      }),

      toggleSpinner(state) {
        this.showSpinner = state
      },

      async reloadList() {
        this.toggleSpinner(true);
        this.selectedRoads = [];
        this.selectedTreatments = [];

        this.selectedYear = this.filterParams?.year || new Date().getFullYear();
        this.selectedDeu = this.filterParams?.fk_deu || -1; // in order not to select other plans
        this.selectedRM = this.filterParams?.rm_id || -1; // in order not to select other plans

        await this.loadTreatments({ rm_id: this.selectedRM });

        await this.loadPlans({ year: this.selectedYear, fk_deu: this.selectedDeu, rm_id: this.selectedRM });
        console.log('Treatment Selection reloadList: selectedDeu, selectedRM, plansList', this.selectedDeu, this.selectedRM, this.plans);

        const label = this.planExists ? this.planApproved ? 'Plan approved' : 'Plan exists' : '';
        this.$emit('labelChanged', label);
        /*
        // Needed to show only roads and treatments that are not added previously
        if (this.planExists) await this.loadPlanDetails({ plan_id: this.currentPlan }); else await this.clearPlanDetailsList();
        console.log('planDetails', this.currentPlan, this.planDetails);
        */
        // Needed to show only roads belonging to the selected DEU
        if (this.selectedDeu) await this.loadSections({ deu_id: this.selectedDeu }); else await this.clearSectionsList();
        this.toggleSpinner(false);
      },

      async addPlan() { // Add new plan
        console.log('creating plan')
        this.toggleSpinner(true);
        const theItem = {
          fk_region: this.filterParams.fk_region,
          year: this.selectedYear,
          fk_deu: this.selectedDeu,
          rm_id: this.selectedRM
        };
        let newPlanId = -1;
        let errDesc = '';
        try {
          const res = await this.addNewPlan(theItem);
          newPlanId = res?.id;
        } catch (err) {
          errDesc = err.message || this.$t('messages.unknown_error');
        } finally {
          this.toggleSpinner(false);
        };

        await savedMessage(errDesc, this.$t('stdCols.plan_name'), 'Routine Maintenance')
        return newPlanId;
      },

      async viewItem() { // Assign selected plans
        if (!this.planExists) { // First create the plan
          const newPlanId = await this.addPlan();
          if (newPlanId == -1) return; // Error occured by creating new plan
        }

        this.toggleSpinner(true);
        let errDesc = '';
        // TODO: Review assigning after Andrey implements API
        this.selectedTreatments.forEach((treatment) => {
          this.selectedRoads.forEach(road => {
            const theItem = {
              plan_id: this.currentPlan,
              fk_road: road.id,
              fk_treatment: treatment.id,
              fk_unit: treatment.fk_unit,
              cost: treatment.cost,

              start: 0,
              end: 0,   // TODO: find the end
              volume: 0,  // TODO: calculate
              cycle_ratio: 1,
              total_cost: 0,

              // TODO: delete these rows after Andrey implements API
              units: treatment.units,
              treatment_description: treatment.description,
              road_description: road.description
            }

            try {
              this.addPlanDetails(theItem);
            } catch (err) {
              errDesc = err.message || this.$t('messages.unknown_error')
            }
          })
        })
        await savedMessage(errDesc, this.$t('stdCols.plan_name'), this.plan_name)
        await this.reloadList();
        this.toggleSpinner(false);
      },
    },

    computed: {
      ...mapState({
        treatmentsList: (state) => state.TestRM.rm_treatment_list,
        plans: (state) => state.TestRM.rm_plan_list,
        planDetails: (state) => state.TestRM.rm_plan_detail_list,
      }),
      ...mapGetters(['roadsList']),
      /*      ...mapGetters('TestRM', ['plansByDeu']),
 
            plansList() {
              return this.plansByDeu(this.selectedDeu);
            },
      */
      planExists() {
        return this.plans.length > 0
      },

      planApproved() {
        return this.planExists ? this.plans[0].approvedBy !== null : false;
      },

      currentPlan() {
        return this.planExists ? this.plans[0].id : -1;
      },

      filteredTreatmentsList() {
        return this.treatmentsList.filter(row =>
          !this.planDetails.some(item => row.id === item.fk_treatment)
        );
      },

      filteredRoadsList() {
        return this.roadsList.filter(row =>
          !this.planDetails.some(item => row.id === item.fk_road)
        );
      },

      addButtonEnabled() {
        const res = this.selectedRoads.length > 0 && this.selectedTreatments.length > 0 && !this.planApproved;
        this.$emit('changeAddButtonStatus', 'selectionForm', res);
        return res;
      },

      treatmentsHeaders() {
        return [
          //{ label: "id", value: "id", sortable: true },
          //{ text: "treatment.treatment_key", value: "treatment_key", sortable: true },
          { label: "stdCols.description", value: "description", sortable: true },
          { label: "label.units", value: "units", sortable: true },
          { label: "tables.cost", value: "cost", sortable: true },
        ]
      },

      roadsHeaders() {
        return [
          //{ label: "id", value: "id", sortable: true },
          { label: "stdCols.description", value: "description", sortable: true },
        ]
      }
    },

    watch: {
      async step(newValue, oldValue) {
        console.log("Treatment selection step", newValue, oldValue, this.filterParams);
        if (newValue !== oldValue && newValue === 'first') this.reloadList();
      },

      selectedTreatments(value) {
        console.log('selectedTreatments', value);
      },

      filterParams: {
        async handler(value) {
          console.log("ref:", this);
          if (this.step !== 'first') return;
          console.log("Treatment Selection paramChanged:", value?.year, value?.fk_region, value?.fk_deu);
          await this.reloadList();
        },
        deep: true
      },
    }
  }
</script>
<style lang="scss" scoped>
.md-card {
  margin: 0px 0;
}
</style>